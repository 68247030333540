<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="teal" dark>
                            <v-toolbar-title>BrandProfile</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <p class="mb-0 mt-2">
                                A BrandProfile is a set of assets that represent your brand or ways of communicating with your company. These assets include logos and other images, color palettes, verified domains, email and postal addresses, and links to your website and social media accounts.
                            </p>
                            <p class="mb-0 mt-2">
                                You can manage all your BrandProfiles at <a href="https://brandprofile.org" target="_blank">brandprofile.org</a>.
                            </p>

                            <!-- <template v-if="!isViewReady">
                                <v-progress-circular indeterminate color="teal" size="24" width="2" class="mr-0"></v-progress-circular>
                            </template> -->
                            <template v-if="isViewReady">
                                <template v-if="brandprofile">
                                <p class="mb-0 pb-0 mt-6">
                                    {{ brandprofile }}
                                    <v-btn icon color="teal" @click="startConnect">
                                        <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                    </v-btn>
                                </p>
                                </template>

                                <template v-if="!brandprofile">
                                <p class="mb-0 pb-0 mt-6">
                                    <a href="#" @click="startConnect">Connect your partner account to a BrandProfile</a>
                                </p>
                                </template>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        // TextLink,
    },
    data: () => ({
        account: null,
        brandprofile: null,
        error: null,
        isViewReady: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadSetting() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadSetting: true });
                const response = await this.$client.account(this.$route.params.accountId).setting.get({ name: 'brandprofile' });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.brandprofile = response.content;
                } else {
                    this.brandprofile = null;
                }
            } catch (err) {
                console.error('failed to load account brandprofile setting', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadSetting: false });
            }
        },
        async startConnect() {
            try {
                this.error = false;
                this.$store.commit('loading', { startConnect: true });
                const response = await this.$client.account(this.$route.params.accountId).partnerConnect.start();
                console.log(`startConnect: response ${JSON.stringify(response)}`);
                if (response?.redirect) {
                    if (typeof window.location.push === 'function') {
                        window.location.push(response.redirect);
                    } else {
                        window.location.href = response.redirect;
                    }
                }
            } catch (err) {
                console.error('failed to start partner connect', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to start BrandProfile verification requset' });
            } finally {
                this.$store.commit('loading', { startConnect: false });
            }
        },
        async verifyConnect(token) {
            try {
                this.error = false;
                this.$store.commit('loading', { verifyConnect: true });
                const response = await this.$client.account(this.$route.params.accountId).partnerConnect.verify(token);
                console.log(`verifyConnect: response ${JSON.stringify(response)}`);
                if (response?.brandprofile) {
                    this.brandprofile = response.brandprofile;
                } else {
                    await this.loadSetting();
                }
            } catch (err) {
                console.error('failed to verify partner connect', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to complete BrandProfile verification requset' });
            } finally {
                this.$store.commit('loading', { verifyConnect: false });
            }
        },
        async init() {
            try {
                if (this.$route.query.token) {
                    await this.verifyConnect(this.$route.query.token);
                    const query = { ...this.$route.query, t: Date.now() };
                    delete query.token;
                    this.$router.replace({ name: 'account-edit-brandprofile', params: { accountId: this.$route.params.accountId }, query });
                } else {
                    await this.loadSetting();
                }
            } catch (err) {
                console.log('init failed', err);
            } finally {
                this.isViewReady = true;
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
